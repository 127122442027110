/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


$neutral-50: #F7F8FB;
$neutral-100: #F1F4F9;
$neutral-100: #E3E5ED;
$neutral-100: #C9D0DE;
$neutral-100: #8D98AD;
$neutral-100: #59637D;
$neutral-100: #3B445A;
$neutral-100: #272F45;
$neutral-100: #14182D;
$neutral-100: #080A1A;



$primario-40: #C5D2FF;
$primario-50: #9CB2FF;
$primario-100: #7795FF;
$primario-200: #557AFF;
$primario-300: #3662FF;
$primario-400: #002DCB;
$primario-500: #011e84;



$secundario-50: #FAEADE;
$secundario-100: #FACDAA;
$secundario-200: #EBA671;
$secundario-300: #DB8746;
$secundario-400: #B56528;



$success-50: #DDEDDF;
$success-100: #B6E0BB;
$success-200: #7BBA82;
$success-300: #579C5F;
$success-400: #37613C;



$warning-50: #FAF2D9;
$warning-100: #FAE6A2;
$warning-200: #F5D97A;
$warning-300: #F0CC54;
$warning-400: #E8BE33;


$error-50: #F7CCC8;
$error-100: #F0766C;
$error-200: #DB5348;
$error-300: #DB392C;
$error-400: #A8190D;


// Generación de clases bg-primario-NUMERO
.bg-primario-50 {
  background-color: $primario-50 !important;
}


.bg-primario-100 {
  background-color: $primario-100 !important;
}


.bg-primario-200 {
  background-color: $primario-200 !important;
}

.bg-primario-300 {
  background-color: $primario-300 !important;
}

.bg-primario-400 {
  background-color: $primario-400 !important;
}


.bg-log-reg {
  background-image: url("../public/images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // opacity: 0.6;
}


.bg-log-reg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba(96, 149, 156, 0.5);
  /* Ajusta el color y la opacidad según tus necesidades */
}


.icon-white {
  fill: white !important;
  color: white !important;
}
